/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@300;400;500;600;700;800&family=Inter:wght@400;500;600;700&family=Old+Standard+TT:wght@400;700&family=Open+Sans:wght@400;500;600;700&family=Oswald:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Montserrat:wght@300;400;500;600;700;800&family=Inter:wght@400;500;600;700&family=Gilroy:wght@400;500;600;700&family=Old+Standard+TT:wght@400;700&family=Open+Sans:wght@400;500;600;700&family=Oswald:wght@400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&family=Inter:wght@300;400;500;600;700;800&display=swap");

/* Hide ReCaptcha Badge */
.grecaptcha-badge {
  visibility: hidden;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
}

select::-ms-expand {
  display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
}

html {
  background-color: #f3f3f3;
  font-family: "Inter", sans-serif;
  overflow-x: hidden !important;
}
button {
  display: inline-block;
  border: none;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
textarea {
  white-space: pre-line;
}

.displayNone {
  display: none;
}
.displayOnMobile {
  display: none;
}
.imgTag {
  color: #ff00aa;
}
.snackbar {
  transition: 0.2s !important;
}

.poppins,
.Poppins {
  font-family: "Poppins", sans-serif !important;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}
@keyframes lightBar {
  to {
    background-position: 100vw;
  }
}
@keyframes rotate {
  to {
    --angle: 360deg;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.contact-us2 {
  border: 1px solid #e0e0e0 !important;
  border-radius: 100px !important;
  height: 36px;
  width: 150px;
  background-color: #ffffff !important;
  margin-left: 10px !important;
  font-family: "Inter", sans-serif;
  text-transform: none !important;
}

.contact-us2 > span {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
}

/* Auth */

.authNav {
  display: flex;
  top: 0;
  width: 80%;
  height: 100%;
  background-color: #b70d0d00;
  justify-content: space-between;
  z-index: 99;
  transition: all 0.4s ease;
  margin-left: 10%;
  margin-top: 10px;
}
.leftNavAuth {
  width: 20%;
  margin-left: 4%;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}
.leftNavAuth > img {
  width: auto;
  height: 50px;
}
.leftNavAuth > h1 {
  font-size: 20px;
  color: #333;
  margin: 0;
  padding-top: 2px;
  padding-left: 15px;
  font-weight: 700;
}
.leftNavAuth > div {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: 8px;
}
.leftNavAuth > div > p {
  font-size: 12px;
  color: #000;
  font-weight: 400;
}
.leftNavAuth > div > img {
  font-size: 5px;
  margin-left: 4px;
  width: 40px;
  height: auto;
}
.popupContainerAuth{
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signInText {
  font-weight: 500;
}
.subscribePopUpCheckout {
  width: 600px;
  height: 510px;
  background-color: #ffffff;
  border-radius: 20px;
  margin-bottom: 50px;
  box-shadow: 4px 4px 3px #00000015;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardElement {
  background-color: #000;
  width: 90%;
}
.addLinkPopUp {
  width: 790px;
  height: 580px;
  background-color: #fff;
  border-radius: 35px;
  margin-top: -50px;
  box-shadow: 4px 4px 3px #00000015;
  padding: 20px 50px;
}
.topBarAddLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 5px;
}
.topBarAddLink > h1 {
  font-size: 24px;
  font-weight: 400;
  margin: 0;
}
.topBarAddLink > p {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 12px;
  padding-top: 10px;
  font-weight: 500;
  cursor: pointer;
  color: #444;
}
.topBarAddLink > p > svg {
  margin-right: 10px;
}
.searchRowAddLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.searchRowAddLink > p {
  font-size: 14px;
  font-weight: 400;
}
.iconsContainer {
  max-height: 492;
  padding: "0px 15px 15px 15px";
}
.addLinkFlexPhone {
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.singleInputAddLink {
  margin-bottom: 5px;
}
.singleInputAddLink > h6 {
  margin: 0;
  padding: 10px 0;
  font-size: 11px;
  font-weight: 600;
}
.iconSectionAddLink {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;
}
.iconSectionAddLink > img {
  width: 60px;
  border-radius: 12px;
}
.iconSectionAddLink > div {
  margin-left: 20px;
}
.iconSectionAddLink > div > h1 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}
.iconSectionAddLink > div > p {
  margin: 0;
  padding-top: 5px;
  font-size: 10.5px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.iconSectionAddLink > div > p > span {
  font-size: 16px;
  margin-right: 5px;
  rotate: 160deg;
}
.iconSectionAddLink > div > p > a {
  color: #0073ff;
  text-decoration: none;
  margin-left: 4px;
  font-weight: 600;
}
.previewPhoneAdd {
  margin-top: -15px;
}
.btnsAddLink {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btnsAddLink > p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #29AEF8;
  transition: 0.2s;
  cursor: pointer;
}
.btnsAddLink > p > svg {
  width: 12px;
  margin-left: 5px;
}
.subscribePopUp {
  width: 1000px;
  height: 580px;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-bottom: 50px;
  box-shadow: 4px 4px 3px #00000015;
  display: flex;
  justify-content: space-between;
}
.leftSubPopUp {
  width: 62%;
  padding: 3% 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}
.leftSubPopUp > h1 {
  margin: 0;
  padding-bottom: 0px;
  font-size: 19px;
  font-weight: 500;
  text-align: center;
  font-family: "Inter", sans-serif;
}
.highlightsOnSubPopUp {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.highlightsOnSubPopUp > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
}
.singleHighlightSub {
  width: 290px;
  height: 200px;
  background-color: #ffffff60;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s;
}
.singleHighlightSub:hover {
  background-color: #ffffff;
}
.singleHighlightSub > div {
  height: 80px;
}
.singleHighlightSub > div > h6 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding-top: 20px;
}
.singleHighlightSub > div > p {
  margin: 0;
  height: 30px;
  font-size: 10.5px;
  font-weight: 300;
  text-align: center;
  color: #818181;
  padding-top: 10px;
}
.singleHighlightSub > img {
  width: 280px;
  border-radius: 15px;
}
.buttonsLeftSub {
  background-color: #ededed;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 100px;
  margin-bottom: 12px;
}
.yearBtn {
  padding: 4px 0px;
  width: 120px;
  text-align: center;
  font-weight: 400;
}
.mthBtn {
  font-weight: 400;
  padding: 4px 0px;
  width: 110px;
}
.buttonLeftSubSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #00000020;
  border-radius: 100px;
  height: 35px;
  font-size: 12px;
  transition: 0.2s;
  cursor: pointer;
}
.buttonLeftSub {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  height: 35px;
  font-size: 12px;
  cursor: pointer;
  /* background-color: #0000ff; */
}
.trialSubText {
  margin: 0;
  text-align: center;
  color: #4F4F4F;
  font-size: 12px;
  padding-top: 90px;
}
.subOptions {
  display: flex;
  justify-content: space-between;
}
.singleSubOption {
  width: 100%;
  height: 320px;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s;
  margin-bottom: 20px;
}
.headerSingleSub {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  align-items: center;
}
.headerProCont {
  display: flex;
  align-items: center;
}
.headerProCont > img {
  height: 25px;
  margin-right: 10px;
}
.headerProCont > p {
  background-color: #4B8CE2;
  margin: 0;
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  padding: 5px 12px;
  border-radius: 100px;
}
.checkSubCont {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkSubCont > svg {
  fill: #ffffff;
  width: 13px;
}
.savingsContSub {
  display: flex;
  align-items: center;
}
.savingsContSub > h6 {
  margin: 0;
  font-size: 15px;
  text-decoration: line-through;
  font-weight: 500;
}
.savingsContSub > p {
  margin: 0;
  font-size: 13px;
  padding-left: 5px;
  color: #4B8CE2;
}
.savingsContSub > p > span {
  text-decoration: none;
}
.singleSubOption > h2 {
  margin: 0;
  margin-left: 20px;
  font-size: 24px;
  font-weight: 500;
  padding-top: 5px;
}
.singleSubOption > span {
  margin: 0;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 500;
  color: #828282;
}
.featuresSection {
  margin-left: 20px;
  margin-top: 20px;
}
.featuresSection > div {
  display: flex;
  align-items: center;
  padding: 6px 0;
}
.featuresSection > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}
.featuresSection > div > p {
  margin: 0;
  font-size: 13px;
  padding-left: 12px;
  font-weight: 400;
}
.subFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subFooter > p {
  margin: 0;
  font-size: 12px;
  width: 200px;
  color: #4F4F4F;
}
.rightSubPopUp {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
}
.exitBtnSub {
  width: 80%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}
.exitBtnSub > svg {
  cursor: pointer;
}
.graphicOnSub > img {
  width: 95px;
  margin-top: 50px;
  margin-bottom: 30px;
  margin-right: 10px;
}
.companyGraphicOnSub {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  height: 100px;
}
.companyGraphicOnSub > h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  padding-top: 8px;
}
.companyGraphicOnSub > span {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  padding-top: 6px;
  color: #828282;
}
.createAccountPopup {
  width: 350px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 60px;
  margin-top: 40px;
  box-shadow: 4px 4px 3px #00000015;
}
.logoSectionCreatePopup {
  width: 350px;
  height: 90px;
}
.topBlackBarCreate {
  background-color: #000;
  height: 40px;
  width: 350px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topBlackBarCreate > img {
  width: 50px;
  padding-top: 40px;
}
.bottomBlackBarCreate {
  background-color: #000000;
  height: 50px;
  width: 350px;
  border-radius: 0 0 100% 100%;
}
.inputsSectionCreatePopup {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  width: 300px;
  margin-left: 25px;
}
.inputsSectionCreatePopup > h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 25px;
}
.fancyOr {
  color: #c5c5c5;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.fancyOr > div {
  height: 1px;
  width: 110px;
  background-color: #c5c5c5;
  margin: 0 5px;
}
.fancyOrSmall {
  color: #c5c5c5;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.fancyOrSmall > div {
  height: 1px;
  width: 110px;
  background-color: #c5c5c5;
  margin: 0 5px;
}
.forgotPassP {
  font-size: 13px;
  color: #A8A8A8;
  margin: 0px 0 25px 0;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.forgotPassP > a {
  text-decoration: none;
  cursor: pointer;
  color: #A8A8A8;
}
.alreadyHaveAccP {
  font-size: 13px;
  color: #A8A8A8;
  margin: 30px 0 25px 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.alreadyHaveAccP > span {
  color: #5092f6;
  cursor: pointer;
  margin-left: 3px;
  display: flex;
  align-items: center;
}
.alreadyHaveAccP > span > svg {
  fill: #000;
  width: 9px;
  margin-right: 10px;
}

/* End Auth */

/* Landing Page */

/* Navbar */
.navButton {
  font-weight: 700;
  text-decoration: none;
  color: rgb(0, 0, 0);
  display: inline-block;
  font-size: 0.8vw;
  z-index: 100;
}
#navButton2 {
  font-weight: 500;
  color: #c2c2c2;
  z-index: 100;
}
.navButton:after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  background: #7d12bfaa;
  transition: 300ms;
}

.navButton:hover:after {
  width: 100%;
}
.navBarHomeContainer {
  width: 100vw;
  height: 70px;
  display: flex;
  flex-direction: column;
  /* position: fixed; */
  z-index: 100;
}
.navBarHome {
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  border-bottom: 2px solid #b1b1b1;
}
.navBarHomeMobile {
  display: none;
}
.navContent {
  display: flex;
  top: 0;
  width: 95%;
  height: 100%;
  background-color: #b70d0d00;
  justify-content: space-between;
  z-index: 99;
  transition: all 0.4s ease;
  margin-left: 2.5%;
}
.leftNav {
  width: auto;
  margin-left: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}
.leftNav > img {
  width: auto;
  height: 42px;
}
.leftNav > h1 {
  font-size: 18px;
  color: #000;
  margin: 0;
  padding-top: 2px;
  padding-left: 10px;
  font-weight: 800;
  line-height: 18px;
  margin-bottom: 1px;
  margin-right: 5px;
}
.leftNav > h1 > span {
  color: #f4f4f4;
  font-size: 13px;
  font-weight: 600;
}
.leftNav > p {
  font-size: 0.7vw;
  color: #f4f4f4;
  margin: 0;
  padding-top: 0.6vw;
  padding-left: 0.5vw;
  font-weight: 600;
}
.leftNav > span {
  height: 30px;
  width: 1px;
  background-color: rgb(179, 179, 179);
  margin: 0px 15px;
  padding-top: 5px;
}
.leftNav > div {
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-left: 0px;
}
.leftNav > div > p {
  font-size: 10px;
  color: #000;
  font-weight: 400;
}
.leftNav > div > img {
  font-size: 5px;
  width: 44px;
  height: auto;
  margin-bottom: 2px;
}
.rightNavHome {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navButtonsFlex{
  display: flex;
}
.navImgHomeCont {
  margin-left: 20px;
}
.navImgHome {
  height: 35px;
  border-radius: 100px;
  box-shadow: 1px 1px 0px #00000030;
  cursor: pointer;
}
.borderBottomGradientHome {
  width: 100%;
  height: 7px;
  background-image: linear-gradient(
    90deg,
    #0055ff 1.54%,
    #7896ff 17.83%,
    #4000f0 35.64%,
    #ff00aa 56.51%,
    #f9f900 83.48%,
    #0055ff 99.26%
  );
  z-index: 200;
  animation: lightBar 5s linear infinite;
  border-radius: 100%;
}

/* End Navbar */

/* Login/Auth */

.loginPopUp{
  width: 300px;
  height: auto;
  padding: 10px 10px 30px 10px;
  border-radius: 150px;
}
.loginFormInputs{
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginFormInputs > img {
  height: 28px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.seperatorDivLogin{
  width: 90%;
  height: 1px;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #dfdfdf;
}

/* End Login/Auth */

/* Account Popup */
.MuiPopover-paper {
  border-radius: 10px !important;
}
.accountPopUp{
  width: 260px;
  height: auto;
  padding: 10px 0;
}
.nameSectionAccount{
  width: 86%;
  margin-left: 7%;
  display: flex;
  align-items: center;
  border: 1.5px solid #EAEAEA;
  padding: 7px 0;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.nameSectionAccount > img {
  height: 42px;
  border-radius: 100px;
  margin-left: 9px;
  box-shadow: 2px 2px 2px #00000015;
}
.nameSectionAccount > div {
  margin-left: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.nameSectionAccount > div > p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}
.nameSectionAccount > div > span {
  font-weight: 500;
  font-size: 10px;
}
.nameSectionAccountEmpty{
  width: 86%;
  margin-left: 7%;
  display: flex;
  align-items: center;
  border: 1.5px solid #EAEAEA;
  padding: 7px 0;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.nameSectionAccountEmpty > img {
  height: 34px;
  border-radius: 100px;
  margin-left: 9px;
  box-shadow: 2px 2px 2px #00000015;
}
.nameSectionAccountEmpty > div {
  margin-left: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.nameSectionAccountEmpty > div > p {
  margin: 0;
  color: #757575;
  font-weight: 500;
  font-size: 12px;
}
.nameSectionAccountEmpty > div > span {
  font-weight: 500;
  font-size: 10px;
}
.otherSectionAccount{
  width: 86%;
  margin-left: 7%;
  display: flex;
  align-items: center;
  border: 1.5px solid #eaeaea;
  padding: 7px 0;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none !important;
  color: #000 !important;
}
.otherSectionAccount:hover{
  border: 1.5px solid #b9b9b9;
}
.otherSectionAccount > img {
  height: 42px;
  border-radius: 100px;
  margin-left: 9px;
  box-shadow: 2px 2px 2px #00000015;
}
.otherSectionAccount > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 25px;
}
.otherSectionAccount > div > svg {
  width: 20px;
}
.otherSectionAccount > div > img {
  width: 35px;
}
.otherSectionAccount > p {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
}
.otherSectionAccountC2A{
  width: 92%;
  margin-left: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaeaea;
  padding: 8px 0;
  border-radius: 100px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none !important;
  color: #fff !important;
  background-color: #000;
}
.otherSectionAccountC2A:hover{
  background-color: #0063F8;
}
.otherSectionAccountC2A > svg {
  height: 18px;
  margin-right: 10px;
  margin-left: -10px;
  border-radius: 100px;
  box-shadow: 0px 0px 6px 2px #ffffff90;
  border: 1.5px solid #fff;
}
.otherSectionAccountC2A > div > img {
  width: 35px;
}
.otherSectionAccountC2A > p {
  margin: 0;
  font-weight: 600;
  font-size: 11px;
}
.otherSectionAccountAlt{
  width: 86%;
  margin-left: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #eaeaea;
  padding: 10px 0;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none !important;
  color: #fff !important;
  background-color: #000;
}
.otherSectionAccountAlt:hover{
  background-color: #0063F8;
}
.otherSectionAccountAlt > svg {
  height: 20px;
  margin-right: 10px;
  margin-left: -10px;
  border-radius: 100px;
  box-shadow: 0px 0px 6px 2px #ffffff90;
}
.otherSectionAccountAlt > div > img {
  width: 35px;
}
.otherSectionAccountAlt > p {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
}
.poplTeamsBtnAccount {
  flex-direction: column;
}
.poplTeamsBtnAccount > img {
  width: 30px !important;
}
.poplTeamsBtnAccount > span {
  font-size: 7px;
}
.logoutBtnAccount {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  margin-right: 10%;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  color: #757575;
  float: right;
}
.logoutBtnAccount:hover {
  color: #333;
}

/* End Account Popup */

/* Generator */

.bigHomeDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  /* background: linear-gradient(
    115.99deg,
    #ffffff 7.11%,
    #e8e8e8 64.31%,
    #f3f3f3 109.75%
  ); */
  background-color: #ecedf5;
}

.homeDiv {
  margin-top: 0px;
  height: calc(100vh - 70px);
  width: 100vw;
  min-width: 950px;
  margin-left: 0%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.generatorContainer {
  background-color: #fff;
  border-radius: 0;
  /* border-radius: 35px 10px 35px 10px; */
  /* border-radius: 10px 35px 10px 35px; */
  margin-top: 0px;
  width: 100%;
  /* padding: 0 9%; */
  /* padding-top: 50px; */
  min-width: 1000px;
  /* max-width: 1400px; */
  height: 100%;
  display: flex;
  justify-content: space-between;
  /* box-shadow: 4px 4px 2px #3333331e; */
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  z-index: 10;
}

.rightSectionGenerator {
  background-color: #fff;
  /* background-color: #DAE8ED; */
  border-left: 2px solid #d6d6dc;
  width: 30%;
  height: auto;
  padding-top: 25px;
  border-radius: 0 35px 35px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.leftSectionGenerator {
  width: 250px;
  max-width: 16%;
  height: 100%;
  border-right: 2px solid #d6d6dc;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-between;
}
.innerListContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
}

.fancyHoverColumn {
  width: 13%;
  height: 100%;
}
.actualHover {
  position: absolute;
  margin-left: 2%;
  width: 4px;
  height: 32px;
  border-radius: 100px;
  background-color: #7d7d7d;
  opacity: 0.5;
  transition: 0.2s;
}
.hoverSelected-true {
  background-image: linear-gradient(
    20deg,
    #9cb1db 1.54%,
    #97ade6 17.83%,
    #87b0d6 35.64%,
    #0055ff 99.26%
  );
  opacity: 1;
}
.hoverSelected-false {
  background-color: #000000;
  opacity: 1;
}
.hoverNonSelect {
  background-color: #7d7d7d;
  opacity: 0.5;
}

.innerList {
  width: 88%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-left: 8%;
}
.innerList > div {
  width: 100%;
  margin-top: 14px;
  cursor: pointer;
  color: #333;
  font-weight: 500;
  transition: 0.3s;
  opacity: 0.6;
  display: flex;
  align-items: center;
}
.innerList > div:hover {
  opacity: 1;
}
.svgContainerLeftSide {
  width: 20px;
  margin-left: 3px;
  display: flex;
  align-items: center;
}
.innerList > div > p {
  font-size: 13px;
  margin: 0;
  height: 16px;
  padding: 7px 10px;
  display: flex;
  align-items: center;
}
.innerList > div > p > img {
  width: 32px;
  margin-right: 3px;
}
.subSectionGenerator{
  width: 80%;
  /* max-width: 220px; */
  margin-left: 5%;
  padding: 12px 5%;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 25px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s;
}
.subSectionGenerator > p{
  font-size: 11px;
  margin: 0;
  font-weight: 600;
  padding: 5px 13px 14px 13px;
  text-align: center;
  color: #4F4F4F;
}
.imageContainerLeftSide {
  margin-bottom: 0px;
  margin-top: 50px;
  opacity: 0.4;
  transition: 0.3s;
  margin-left: 12%;
}
.imageContainerLeftSide:hover {
  opacity: 1;
}
.imageContainerLeftSide > img {
  width: 60px;
}

/* Middle Section  */
.middleSectionGenerator {
  width: 52%;
  height: 100%;
  /* border-left: 2px solid #d6d6dc; */
}
.middleTextFieldContainer {
  width: 94%;
  margin-top: 30px;
  margin-left: 35px;
}
.h1InputSection{
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  padding-top: 15px;
  color: #575757;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.h1InputSection > svg{
  width: 20px;
  margin-right: 10px;
}
.subheaderInputSection{
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  padding-top: 10px;
  color: #757575;
}
.subheaderInputSection2{
  margin: 0;
  font-size: 11px;
  padding-top: 7px;
  font-weight: 500;
  color: #757575;
}
.headerSectionInput{
  margin-bottom: 30px;
}
.generateButtonDiv {
  transition: 0.2s;
}
.togglerContainer {
  margin-top: 30px;
  margin-bottom: 30px;
}
.buttonTogglerText {
  padding-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #575757;
  /* color: #000; */
}
.twoInputRow {
  display: flex;
  margin: 15px 0;
}
.fullLengthTextRow {
  margin: 15px 0;
}
.lastInputRow {
  margin-bottom: 30px;
}
.standardInputContainer {
  width: 95%;
}

.poplCardContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 84vh;
}
.leftSidePoplCard {
  width: 50%;
}
.rightSidePoplCard {
  /* background-color: rgba(224, 224, 224, 0.27); */
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.poplCardBtnDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.createPbtn {
  margin-top: 15px;
  font-size: 13px;
  font-weight: 500;
  color: rgb(94, 94, 94);
  cursor: pointer;
}
.actualPhone {
  border-radius: 25px;
  border: 3px solid #DCDCDC;
  height: 475px;
  width: 240px;
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.toolbarPhone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 5px solid #ffffff00;
}
.toolbarPhone > p {
  margin: 0;
  height: 15px;
  font-size: 10px;
  font-weight: 600;
  width: 22%;
  text-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}
.toolbarPhone > p > svg {
  margin-bottom: 2px;
}
.toolbarPhone > div {
  width: 50%;
  background-color: #DCDCDC;
  height: 17px;
  border-radius: 0 0 10px 10px;
}
.phoneContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  flex-wrap: nowrap;
  height: 100%;
  max-height: 900px;
  overflow: scroll;
  border-radius: 0px 0px 21px 21px;
}
.phoneContent::-webkit-scrollbar {
  display: none;
}
.topOfPhone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.bannerPhone {
  width: 100%;
  height: 95px;
  margin-top: 20px;
}
.photoPhone {
  width: 86px;
  height: 86px;
  border-radius: 100px;
  margin-top: -50px;
  border: 5px solid #fff;
}
.textSectionPhone > h1 {
  text-align: center;
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  padding-top: 6px;
  padding-bottom: 3px;
  width: 210px;
}
.textSectionPhone > p {
  width: 210px;
  text-align: center;
  margin: 0;
  font-size: 10px;
  padding-top: 4px;
  font-weight: 400;
}
.saveContactPhoneContainer {
  background-color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.saveContactPhone {
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 150px;
  height: 35px;
  font-size: 11px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.linkContainerPhone {
  width: 240px;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 30px;
  background-color: #ffffff;
}
.linkContainerPhoneEmpty {
  background-image: url(../img/emptyLinks2.png);
  background-size: 270%;
  background-position: 0px 0px;
  background-repeat: repeat-x;
  /* opacity: 0.2; */
  /* filter: blur(8px); */
/* filter: brightness(1); */
/* filter: contrast(200%); */
}
.linkOnPhone {
  margin: 5px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.linkOnPhone > img {
  width: 55px;
  height: 55px;
  box-shadow: 0px 0px 5px #00000025;
  cursor: pointer;
  border-radius: 12px;
}
.linkOnPhone > p {
  margin: 0;
  font-size: 7.5px;
  padding-top: 5px;
}
.linkOnPhoneHighlighted {
  width: 82%;
  height: 80px;
  margin: 5px 0;
  padding: 0px 9%;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.linkOnPhoneHighlighted > img {
  width: 55px;
  height: 55px;
  box-shadow: 0px 0px 5px #00000025;
  cursor: pointer;
  border-radius: 12px;
}
.linkOnPhoneHighlighted > div {
  margin-left: 10px;
}
.linkOnPhoneHighlighted > div > p {
  margin: 0;
  font-size: 13px;
  height: 11px;
  font-weight: 600;
}
.linkOnPhoneHighlighted > div > span {
  font-size: 9px;
  font-weight: 400;
  margin-left: 1px;
}
.picUploadPoplCard {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 35px;
}
.picUploadPoplCard > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.picInputPoplCard {
  margin-top: 15px;
  text-align: center;
}
.bannerInputPoplCard {
  margin-top: 17.5px;
}
.labelPoplCard {
  font-size: 13px;
  font-weight: 500;
  margin: 0;
  color: #4f4f4f;
  display: flex;
  align-items: center;
}
.labelPoplCard > svg {
  width: 12px;
  fill: #a9a9a9;
  margin-left: 10px;
  cursor: pointer;
  transition: 0.2s;
}
.labelPoplCard > svg:hover {
  fill: #000;
}
.themeSelectPoplCard {
  margin-top: 30px;
}
.themeSelectPoplCard > div {
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: space-around;
  height: 35px;
}
.singleOptionBorder {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}
.singleOption {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  transition: 0.2s;
}
.singleOptionCustom {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.aboutSectionPoplCard {
  
}
.aboutSectionPoplCard > div {
  display: flex;
  justify-content: space-between;
  width: 100%
}
.linkSectionPoplCard {
  margin-top: 20px;
}
.addBtnLinks {
  font-size: 10px;
  border: 1px solid #cdcdcd;
  padding: 3px 4px;
  border-radius: 4px;
  font-weight: 600;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.2s;
}
.addBtnLinks:hover {
  box-shadow: 2px 2px 0 0 #000;
}
.linksList {
  margin-top: 15px;
  max-height: 27vh;
  overflow: auto;
}
.linksList::-webkit-scrollbar {
  display: none;
}
.singleLinkDrag {
  display: flex;
  background-color: #F2F2F2;
  /* border: 1px solid #0000003b; */
  border-radius: 12px;
  margin: 5px 0;
  align-items: center;
  justify-content: space-between;
  padding: 3px 8px;
}
.innerSingleLink {
  display: flex;
  align-items: center;
  margin: 6px 0;
  width: 85%;
}
.iconAndTextLinks {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.iconAndTextLinks > img {
  width: 36px;
  margin-right: 10px;
}
.iconAndTextLinks > p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: rgb(75, 75, 75);
}
.ellipsisOnLinks {
  display: flex;
  align-items: center;
}
.ellipsisOnLinks > svg {
  fill: #868686;
  width: 12px;
  margin-right: 5px;
  cursor: pointer;
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
.emptyLinks {
  margin-top: 15px;
  border-radius: 12px;
  box-shadow: inset 0px 0px 8px 6px #00000025;
}
#animate-area {
  width: 98%;
  height: 120px;
  background-image: url(../img/emptyLinks.png);
  background-size: 180%;
  background-position: 0px 0px;
  background-repeat: repeat-x;
  animation: animatedBackground 35s linear infinite alternate;
}
.emptyLinks > div {
  margin-top: 20px;
}
.addLinksPoplCard {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  opacity: 0;
  background-color: #00000070;
  transition: 0.3s;
  cursor: pointer;
}
.addLinksPoplCard:hover {
  opacity: 1;
}
.addLinksPoplCard > p {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 3px 3px 5px #00000090;
}

/* AI Section */
.aiContainer {
  display: flex;
}
.leftSideAi {
  width: 50%;
  margin-right: 3%;
}
.textInputAi {
  margin-bottom: 10px;
}
.aiLogoFlexDiv {
  display: flex;
  margin-bottom: 30px;
}
.aiLogoFlexDiv > img {
  width: auto;
  max-width: 200px;
  height: 80px;
  margin-left: 15px;
}
.removeLogoAi {
  background-color: #ffffffd1;
  border: 1px solid #9e9e9e00;
  width: 25px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-left: -20px;
  margin-top: -5px;
  cursor: pointer;
  transition: 0.2s;
}
.removeLogoAi > svg {
  width: 10px;
  fill: #9e9e9e;
}
.removeLogoAi:hover {
  background-color: #fff;
  border: 1px solid #9e9e9e;
}
.prevUploadsBtn {
  font-size: 11px;
  color: #494949;
  font-weight: 500;
  width: 135px;
  cursor: pointer;
}
.imgInputAi {
  margin-top: 25px;
  padding-top: 10px;
  margin-bottom: 25px;
  border-top: 1px solid #d6d6dc;
}
.imgInputAi > p {
  font-size: 11px;
  color: #494949;
  font-weight: 500;
}
.inputContainerAi {
  width: 48%;
}
.fileUploadAi {
  width: 100%;
  height: 80px;
  border-radius: 8px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fileUploadAi > svg {
  width: 25px;
}
.fileUploadAi > span {
  width: "80%";
  font-size: 9px;
  padding-top: 8px;
  text-align: "center";
}
.preserveLogoAi {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 10px;
}
.preserveLogoAi > div {
  width: 28px;
  display: flex;
  align-items: center;
}
.preserveLogoAi > p {
  margin: 0;
  padding-left: 8px;
  font-size: 11px;
  font-weight: 500;
  color: #777777;
  display: flex;
  align-items: center;
}
.preserveLogoAi > p > svg {
  margin-left: 4px;
  width: 14px;
  fill: #777777;
  cursor: pointer;
}
.preserveLogoAi > p > svg:hover {
  fill: #000;
}
.bottomInputsAi {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}
.leftBottomInputsAi {
  width: 48%;
}
.rightBottomInputsAi {
  width: 42%;
}
.generatingTag {
  margin: 0;
  font-size: 10px;
  float: right;
  display: flex;
  align-items: center;
  padding-top: 8px;
  color: #6F6F6F;
}
.processingSpan {
  min-width: 35px;
  font-size: 11px;
  text-align: right;
}
.counterSpan {
  display: block;
  width: 65px;
  font-size: 11px;
  text-align: right;
  margin-right: 4px;
}
.counterSpan2 {
  display: block;
  width: auto;
  max-width: 100px;
  font-size: 11px;
  text-align: center;
}
.singleInputBottom {
  margin-bottom: 0px;
}
.topRowSingleInputBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
}
.topRowSingleInputBottom > p {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  color: #494949;
  display: flex;
  align-items: center;
}
.topRowSingleInputBottom > p > svg {
  margin-left: 4px;
  width: 13px;
  fill: #777777;
  cursor: pointer;
}
.topRowSingleInputBottom > p > svg:hover {
  fill: #000;
}
.topRowSingleInputBottom > span {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 12px;
}
.rightSideAi {
  width: 47%;
}
.imageContAi {
  border: 1px solid #777;
  width: 100%;
  height: auto;
  min-height: 400px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aiQRpreviewCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  width: 100%;
}
.loaderWithinAiPreview {
  width: 92%;
  height: 10px;
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
}
.loaderWithinAiPreview > div {
  margin-left: 4px;
}
.miniQRPreviews {
  display: flex;
}
.QRminiPreview {
  margin: 20px 10px;
}
.QRminiPreview > img {
  border-radius: 5px;
  width: 60px;
  cursor: pointer;
}
.selectedQRpreview > img {
  box-shadow: 0px 0px 1px 2px rgb(255, 166, 0);
}
.buttonsContAi {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonsContAi > div {
  border: 1px solid #7777;
  border-radius: 6px;
  width: 32%;
  margin: 0 5px;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}
.buttonsContAi > div:hover {
  border: 1px solid #77777740;
}
.buttonsContAi > div > svg {
  width: 13px;
  margin-left: 5px;
  fill: #3c3c3c;
}
.advancedSettingsAi {
  width: 48%;
}
.showAdvancedBtn {
  font-size: 11px;
  color: #1d9cf1;
  width: 145px;
  font-weight: 500;
  cursor: pointer;
}

/* End AI Section */

/* Start Early Access */

.earlyAccessTag {
  background-color: #831df1;
  color: #fff;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 10px;
  width: 80px;
}
.earlyAccessContainer {
  display: flex;
  justify-content: flex-start;
}
.queueDiv {
  background-color: #6bccec35;
  border: 1px solid #6bccec;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 170px;
}
.queueDiv > p {
  margin: 0;
  padding-left: 15px;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.queueDiv > p > span {
  width: 1px;
  margin-left: 15px;
  height: 1px;
}
.leftSideEA {
  width: 40%;
  margin-right: 8%;
  margin-left: 2%;
}
.rightSideEA {
  width: 570px;
  display: flex;
  margin-top: -30px;
  justify-content: flex-start;
}
.rightSideEA > div > img {
  width: 260px;
  border-radius: 20px;
  margin-bottom: 30px;
  /* overflow: hidden; */
  box-shadow: 4px 4px 6px 0px #00000045;
}
.first3QRsEA {
  width: 290px;
}
.second3QRsEA {
  width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 850px;
  height: calc(100vh - 70px);
  overflow-y: hidden;
}
.earlyAccessInputs {
  text-align: center;
  margin-top: 150px;
}
.earlyAccessInputs > h1 {
  font-size: 17px;
  margin: 0;
}
.earlyAccessInputs > p {
  font-size: 13px;
  margin: 0;
  padding-left: 2px;
  padding-top: 8px;
  font-weight: 600;
}
.signInTextEA {
  padding: 10px 0px !important;
  margin-left: 2px !important;
}
.signInTextEA > span {
  margin-left: 4px;
  text-decoration: underline;
  cursor: pointer;
}
.lowerPortionEA {
  width: 400px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.lowerPortionEA > h1 {
  font-size: 16px;
  margin: 0;
  padding-bottom: 15px;
}
.checkboxContEA {
  display: flex;
  align-items: center;
  margin-left: -10px;
}
.checkboxDivEA {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  margin-right: 20px;
}

/* End Early Access */


/* Right Section */

.topSectionRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 210px; */
  margin-top: 5px;
  margin-bottom: 20px;
}
.btnTest {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #19D7D4 0%, #565DF4 100%), linear-gradient(91.75deg, #1FDAD2 0%, #5B5EFF 100%);
  text-transform: none;
  /* border: 1px solid #e0e0e0; */
  box-shadow: 0px 0px 1px 1px #00000025;
  margin-bottom: 10px;
  border-radius: 100px;
  padding: 6px 20px;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.6s;
}
.btnTest:hover {
  box-shadow: 0px 0px 2px 1px #00000065;
}
.tipDiv {
  background-color: #78f4ff2d;
  border: 2px solid #406de030;
  width: 75%;
  padding: 10px 15px;
  border-radius: 6px;
  margin-top: 200px;
}
.tipDiv > h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: #414348d2;
  padding-bottom: 5px;
}
.tipDiv > p {
  margin: 0;
  font-size: 11px;
  padding-top: 8px;
  font-weight: 400;
  color: #444;
  padding-bottom: 5px;
}
.tipDiv > p > span {
  margin: 0;
  font-size: 12px;
  padding-top: 8px;
  font-weight: 600;
  color: #444;
}
.back2EditBtn {
  margin: 0 0 15px 0;
  font-size: 11px;
  transition: 0.3s;
  cursor: pointer;
  font-weight: 500;
  color: #252525;
}
.back2EditBtn:hover {
  color: #25252560;
}
.downloadBtnsFromAiQr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.downloadBtnsFromAiQr > div {
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  padding: 10px 40px;
  margin: 0 5px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;
}
.downloadBtnsFromAiQr > div:hover {
  background-color: #00000095;
}
.qrSquared {
  border-radius: 0px;
}
.qrRounded {
  border-radius: 17px;
}
.qrRounded > svg {
  border-radius: 6px !important;
}
.qrNoBorder {
  border-radius: 17px;
}
.qrCodeDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}
@keyframes lightBar {
  to {
    background-position: 120px;
  }
}
.qrOverlay {
  width: 150px;
  height: 150px;
  background-color: #ffffffb9;
  /* background-image: linear-gradient(
    90deg, */
  /* #ffffffd0 1.54%,
    #fffffff5 17.83%,
    #ffffffe2 35.64%,
    #ffffffe0 83.48%,
    #ffffffd0 99.26% */
  /* #ffffffb0 1.54%,
    #ffffffab 17.83%,
    #ffffffd7 35.64%,
    #ffffffc4 83.48%,
    #ffffffb0 99.26%
  );
  */
  /* z-index: 200; */
  /* animation: lightBar 5s linear infinite; */
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add2Collection {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #4180ff;
  cursor: pointer !important;
  margin-top: 20px;
}
.editSectionRight {
  /* background-color: #7896ff; */
  width: 80%;
  min-width: 260px;
}
#qrSVG {
  text-align: center;
  text-anchor: middle;
}
.sectionHeader {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sectionHeader > h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #4F4F4F;
}
.downloadSection {
  width: 90%;
  min-width: 240px;
  margin-left: 5%;
  margin-top: 10px;
}
.downloadSectionContent {
  display: flex;
}
.downloadSectionContent {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.colorSelectDiv {
  padding-bottom: 10px;
}
.colorSelectDiv:last-child {
  padding-bottom: 0px;
}
.colorSelectDiv > p {
  margin: 0;
  font-size: 11.5px;
  color: #444;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: 500;
}
.colorSectionContent {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.colorSectionContent > p {
  font-size: 12px;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  color: #333;
  font-weight: 500;
  width: 70px;
}
.colorSectionContentBG {
  display: flex;
  align-items: center;
}
.colorSectionContentBG > p {
  font-size: 12px;
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  color: #333;
  font-weight: 500;
}
.colorOption {
  margin: 0 4px;
  width: 19px;
  height: 19px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}
.colorOption > div {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.colorOption > div > svg {
  fill: #898989;
  width: 10px;
}
.colorOptionSmall {
  margin: 0 4px;
  width: auto;
  height: 19px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}
.colorOptionSmall > div {
  width: 14px;
  height: 14px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
}
.colorOptionSmall > div > svg {
  fill: #898989;
  width: 8px;
}
.colorOptionSmall > p {
  margin: 0;
  font-size: 11px;
  margin-left: 5px;
  margin-right: 8px;
  font-weight: 500;
}
.borderSectionContent {
  width: 94%;
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}
.styleSectionContent {
  width: 90%;
  margin-left: 4%;
  padding: 8px 0;
}
.styleSectionButtons {
  width: 100%;
  display: flex;
  /* justify-content: space-around; */
}
.borderToggle {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}
.borderToggle > p {
  margin: 0;
  padding: 5px 0px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
.logoSectionContent {
  /* background-color: rgb(244, 244, 244); */
  width: 95%;
  border-radius: 5px;
}
.logoToggle {
  display: flex;
  align-items: center;
}
.logoToggle > p {
  margin: 0;
  padding: 10px 0px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.warningDivVCF {
  display: flex;
  margin-left: 11px;
  background-color: #d8e5ff;
  border-radius: 5px;
  padding: 8px 10px;
}
.warningDivVCF > svg {
  fill: #787878;
}
.warningDivVCF > p {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  padding-left: 10px;
  color: #787878;
}
.qrCodeLogo {
  font-size: 12px;
  padding: 5px 10px;
  font-weight: 600;
  font-style: italic;
}
.qrCodeLogo > i {
  font-size: 15px;
  margin-right: 10px;
}
.uploadLogoBtn {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 15px;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  background-color: #ffffff;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
}
.custom-file-upload > i {
  padding-right: 6px;
}
.file-preview {
  margin: 0 10px;
}
.analyticsEligibility {
  margin-left: 10px;
}
.analyticsEligibilityCheck {
  display: flex;
  align-items: center;
}
.analyticsEligibilityCheck > p {
  font-size: 13px;
  margin: 0;
  padding-left: 10px;
  font-weight: 400;
}
.analyticsEligibilityFlex > div {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
.analyticsEligibilityFlex > div > svg {
  width: 18px;
  fill: #000;
}
.analyticsEligibilityFlex > div > p {
  margin: 0;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 600;
}
.analyticsEligibilityFlex > p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  padding-top: 10px;
  color: #3a3a3a;
}
.analyticsEligibilityFlex2 > div {
  display: flex;
  align-items: center;
}
.analyticsEligibilityFlex2 > div > svg {
  width: 18px;
  height: 18px;
  fill: rgb(223, 104, 104);
}
.analyticsEligibilityFlex2 > div > p {
  margin: 0;
  padding-left: 5px;
  font-size: 13px;
  font-weight: 600;
}
.analyticsEligibilityFlex2 > p {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  padding-top: 10px;
  color: #2b2b2b;
}
.proBadgeAnalytics {
  background-color: #fff;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
  /* position: absolute;
  left: 72px;
  bottom: 44%; */
}
/* .proBadgeAnalytics > svg {
  width: 90%;
} */

.accordion {
  background-color: #df1c1c00 !important;
  box-shadow: 0 0 0 0 !important;
  border-top: 1px solid #e8e8e8;
}
.MuiAlert-icon {
  display: flex;
  align-items: center;
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
  min-height: 40px !important;
  display: flex;
  align-items: center;
}
.MuiAccordionSummary-root.Mui-expanded {
  margin: 0 !important;
  min-height: 40px !important;
}
.MuiIconButton-root {
  padding: 0px 12px !important;
}
.MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
  min-height: 40px;
}
.MuiAccordionDetails-root {
  padding: 0px 0px 14px 10px !important;
}
.MuiInput-underline:after {
  border: 1px solid #333 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #000 !important;
}
.accordionTop {
  background-color: #df1c1c00 !important;
  box-shadow: 0 0 0 0 !important;
  border-top: 0px solid #e8e8e8 !important;
}
.accordionHeader {
  background-color: #7d7d7d00 !important;
  box-shadow: 0 0 0 0 !important;
  height: 0px;
}
.accordionHeaderH6 {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  color: #4F4F4F;
}
.accordionHeaderH62 {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  height: 20px;
  color: #4F4F4F;
}

.details {
  align-items: "center";
  border: "1px solid rgba(0,0,0,0.1)";
  border-radius: 4;
}
.customPicker {
  cursor: pointer;
  width: 19px;
  height: 19px;
  border: 1px solid #c2c2c2;
  border-radius: 100px;
  font-size: 13px;
  font-weight: 500;
  color: #8f8f8f;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
}
.customPicker > svg {
  cursor: pointer;
  width: 10px;
  position: absolute;
  fill: #000;
}
.customPicker > input {
  cursor: pointer;
}
/* End Generator */

/* Beneath Generator */

.companyList {
  width: 100vw;
  margin-top: 100px;
  text-align: center;
  padding-bottom: 40px;
}
.companyList > h1 {
  margin: 0;
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  padding-bottom: 50px;
  color: rgb(76, 76, 76);
}
.trustedByContainer > img {
  width: 900px;
  transition: 0.4s;
}
.dividerOnHome {
  width: 100vw;
  height: 300px;
}
.logoDivDivider {
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.logoDivDivider > img {
  border-radius: 100px;
  background-color: #fff;
  width: 130px;
}
.greyPortionDivider {
  background-color: #f3f3f300;
  position: absolute;
  width: 100vw;
  height: 150px;
}
.whitePortionDivider {
  width: 100vw;
  height: 150px;
  position: absolute;
  background-color: #fff;
  margin-top: 150px;
  border-radius: 100px 100px 0 0;
}
.alsoFromDiv {
  background-color: #fff;
  padding-top: 40px;
}
.alsoFromDiv > h1 {
  margin: 0;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 50px;
}
.alsoFromFlexDiv {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-left: 10%;
}
.singleAlsoFrom {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 300px;
}
.singleAlsoFrom > h6 {
  font-size: 15px;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  margin: 0;
  padding-top: 30px;
}
.singleAlsoFrom > p {
  font-size: 14px;
  font-weight: 400;
  font-family: "DM Sans", sans-serif;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 30px;
  width: 190px;
}
.singleAlsoFrom > a {
  font-size: 14px;
  font-weight: 600;
  font-family: "DM Sans", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.singleAlsoFrom > a > svg {
  width: 12px;
  margin-left: 8px;
}
.singleAlsoFrom > img {
  height: 150px;
  transition: 0.4s;
}
.mobileAppLinksHome {
  width: 100vw;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 150px;
}
.mobileAppLinksHome > div {
  width: 100px;
}
.footerContainer {
  background-color: #000;
  padding: 70px 0;
  display: flex;
  justify-content: space-around;
  width: 100vw;
}
.column1Footer {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.column1Footer > p {
  width: 200px;
  color: #bbbbbb;
  padding-top: 100px;
  font-weight: 400;
  margin: 0;
  font-size: 14px;
}
.column1Footer > p > span {
  color: #bbbbbb;
  font-weight: 600;
  margin: 0;
}
.footerImgDiv {
  display: flex;
  align-items: center;
}
.footerImgDiv > img {
  height: 40px;
}
.footerImgDiv > div {
  height: 50px;
  width: 2px;
  background-color: #ffffff90;
  margin-left: 10px;
  margin-right: 10px;
}
.appQRFooter {
  display: flex;
  margin-top: 30px;
}
.appQRFooter > p {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  color: #fff;
  width: 100px;
  padding-right: 20px;
}
.appQRFooter > img {
  width: 100px;
}
.column2Footer {
  width: 200px;
  display: flex;
  flex-direction: column;
  font-family: "DM Sans", sans-serif;
}
.column2Footer > span {
  color: #999;
  margin-bottom: 20px;
}
.column2Footer > a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
}

/* End Beneath Generator */

/* End Landing Page */

/* Blog */

.bigBlogDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
}
.banner {
  width: 100%;
  height: 45px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  font-family: "Inter", sans-serif;
}
.blogNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  margin-top: 25px;
}
.blogNav > a > img {
  width: 90px;
  height: 90px;
}
.blogNav > div {
  display: flex;
}
.blogNav > div > p {
  font-size: 14px;
  margin: 0 20px;
  color: #4f4f4f;
  font-weight: 500;
}
.blogCategorySelect {
  display: flex;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  margin-top: 30px;
}
.blogCategorySelect > h1 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  margin-right: 20px;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.2s;
}
.blogCategorySelect > p {
  margin: 0;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  margin-right: 20px;
  transition: 0.2s;
}
.blogCategorySelect > p:hover, .blogCategorySelect > h1:hover {
  opacity: 0.7;
}
.blogList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}
.singleBlogItem {
  width: 40%;
  height: 500px;
  overflow-y: scroll;
  margin-bottom: 40px;
  background-color: rgb(233, 235, 255);
  padding: 4%;
  border-radius: 20px;
}
.singleBlogItem::-webkit-scrollbar {
  display: none;
}

.blogMakerPopUp {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blogMakerPopUp > div {
  border-radius: 10px;
  background-color: #fff;
  width: 1100px;
  height: 550px;
  padding: 50px 50px;
  display: flex;
}
.leftSideBlogMaker {
  width: 30%;
  height: 100%;
  padding-right: 2%;
  border-right: 1px solid #bebebe;
  display: flex;
  flex-direction: column;
}
.leftSideBlogMaker > span {
  width: 10px;
  height: 10px;
}
.rightSideBlogMaker {
  width: 65%;
  margin-left: 1%;
  /* background-color: #0000ff; */
  height: 500px;
  overflow-y: scroll;
}
.rightSideBlogMaker::-webkit-scrollbar {
  display: none;
}
.singleContentPc {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0 15px;
  font-size: 13px;
  justify-content: space-between;
  border: 1px solid #c4c4c4;
}
/* End Blog */

/* Collections */

.viewScanContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000015;
  z-index: 24000;
}
.viewScanpop {
  background-color: #fff;
  width: 700px;
  height: 460px;
  border-radius: 10px;
  padding: 30px 40px 40px 40px;
  display: flex;
  justify-content: space-between;
}
.leftSideScanPop {
  width: 45%;
  /* background-color: yellowgreen; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.rightSideScanPop {
  width: 53%;
  /* background-color: yellowgreen; */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.qrgLogoWithText {
  display: flex;
  align-items: center;
  width: 100%;
}
.qrgLogoWithText > svg {
  width: 75px;
  margin-left: 2px;
}
.qrgLogoWithText > div > svg {
  width: 16px;
}
.qrgLogoWithText > div {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.qrgLogoWithText > span {
  width: 1px;
  margin: 0 5px;
  height: 30px;
  background-color: #dbdbdb;
  border-radius: 10px;
}
.qrgLogoWithText > p {
  margin: 0;
  padding-left: 10px;
  font-weight: 500;
  font-size: 15px;
}
.viewScanQR {
  /* background-color: #00000030; */
  width: 100%;
  height: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scanSectionPopup {
  /* background-color: yellowgreen; */
  display: flex;
  margin-top: 60px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.scanSectionPopup > span {
  height: 130px;
  width: 1px;
  background-color: #D9D9D9;
  margin: 0 10px;
}
.actualScanCountSection {
  text-align: center;
  width: 160px;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: yellow; */
}
.actualScanCountSection > h1 {
  margin: 0;
  height: 50px;
  padding-top: 10px;
  /* background-color: whitesmoke; */
  font-weight: 500;
  font-size: 44px;
}
.labelSectionViewScans {
  /* background-color: wheat; */
  height: 35px;
}
.labelSectionViewScans > span {
  margin: 0;
  font-size: 10px;
  color: #7B7B7B;
  font-weight: 400;
  line-height: 1px;
}
.labelSectionViewScans > p {
  line-height: 15px;
  padding-top: 8px;
  margin: 0;
  font-size: 12px;
  color: #7B7B7B;
  font-weight: 400;
}

.skellyCont {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 70px;
}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 20px;
  height: 20px;
  border-radius: 3px;
}
.skeleton-text2 {
  width: 20px;
  height: 40px;
  border-radius: 3px;
}
.skeleton-text3 {
  width: 20px;
  height: 60px;
  border-radius: 3px;
}

.changeURLpop {
  background-color: #fff;
  width: 500px;
  height: 250px;
  border-radius: 10px;
  padding: 20px 40px;
}
.changeURLpop > h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  padding-top: 10px;
}
.changeURLpop > p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 30px;
}

.contactUsPop {
  background-color: #fff;
  width: 600px;
  height: auto;
  border-radius: 10px;
  padding: 20px 40px;
  margin-bottom: 200px;
}
.contactUsPop > h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  padding-top: 10px;
}
.contactUsPop > p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 30px;
}
.buttonsDivSupport {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.buttonsDivSupport > div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c1c1c1;
  border-radius: 100px;
  padding: 10px 20px;
  font-size: 12px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.2s;
}
.btnDivChangeURL {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.sidebarCollections {
  position: absolute;
  background-color: #00000025;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  opacity: 1;
  transition: 0.5s;
}
.actualSidebar {
  background-color: #fff;
  width: 270px;
  height: 100%;
  opacity: 1;
  transition: 0.5s;
  box-shadow: 0px 10px 15px 5px #00000060;
}
.actualSidebar > div {
  padding: 10px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.qrgLogo {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-top: 10px;
}
.qrgLogo > div {
  margin-bottom: 2px;
}
.qrgLogo > div > p {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  padding-left: 5px;
  margin-top: -8px !important;
  font-weight: 600;
}
.qrgLogo > div > h1 {
  margin-top: -5px !important;
  margin: 0;
  font-size: 27px;
}
.exitSidebar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}
.exitSidebar > svg {
  width: 14px;
  fill: #696969;
  cursor: pointer;
}
.buttonsSidebar {
  height: 72%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.buttonsSidebar > span {
  width: 200px;
  height: 2px;
  background-color: #e0e0e0;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
}
.buttonsSidebar > div, .buttonsSidebar > a {
  display: flex;
  align-items: center;
  width: 190px;
  height: 45px;
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 0px 20px;
  cursor: pointer;
  transition: 0.2s;
}
.buttonsSidebar > div:hover, .buttonsSidebar > a:hover {
  background-color: rgb(241, 241, 241);
}
.buttonsSidebar > div > svg, .buttonsSidebar > a > svg {
  width: 20px;
  fill: #000;
}
.buttonsSidebar > div > p, .buttonsSidebar > a > p {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding-left: 15px;
}
.logoutBtnSidebar {
  font-size: 14px;
  font-weight: 500;
  width: 80%;
  display: flex;
  opacity: 0.7;
}
.logoutBtnSidebar > svg {
  width: 20px;
  margin-right: 10px;
}
.collectionsContainer {
  background-color: #ecedf5;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.collectionsContainer > img {
  position: absolute;
  width: 200px;
  bottom: 0;
  right: 0;
}
.collectionsContainer > canvas {
  position: absolute;
  width: 200px;
  bottom: 0;
  right: 0;
  background-color: #0000ff;
}
.NavCollections {
  background-color: #fff;
  width: 100vw;
  height: 55px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #d6d6dc;
}
.NavCollections > div {
  display: flex;
  align-items: center;
}
.navBtnDiv {
  margin: 0px 5px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.navBtnDiv > div {
  width: 150px;
  height: 4px;
  border-radius: 100px 100px 0 0;
  transition: 0.3s;
}
.byPoplDivCollection {
  margin-right: 60px;
  font-size: 12px;
}
.rightSideCollections {
  margin-top: 20px;
}
.analyticsSectionCollections {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 18px;
  align-items: center;
  height: 106px;
  width: 100%;
}
.actualAnalysisBox {
  background-color: #fff;
  width: 250px;
  height: 90px;
  border-radius: 5px;
  margin-right: 20px;
  box-shadow: 2px 2px 7px #00000015;
}
.savedQRSection {
  width: 75%;
  margin-left: 4%;
  margin-top: 20px;
  margin-left: 3%;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
.savedQRHeader {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 20px;
  padding-top: 50px;
}
.savedQRHeader > span {
  font-size: 15px;
  font-weight: 400;
  color: rgb(151, 151, 151);
  margin-left: 7px;
}
.loaderDivCollection {
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spacerListOfQRs {
  background-color: #0000ff00;
  width: 100%;
  height: 150px;
}
.listOfSavedQRs {
  display: flex;
  flex-wrap: wrap;
  margin-left: 2px;
  overflow-y: scroll;
  max-height: 65vh;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.listOfSavedQRs::-webkit-scrollbar {
  display: none;
}
.singleSavedQR {
  background-color: #fff;
  margin-bottom: 20px;
  margin-right: 30px;
  border-radius: 15px;
  /* box-shadow: 3px 3px 3px #00000015; */
  transition: 0.2s;
  width: 400px;
  height: 160px;
}
.singleSavedQR:hover {
  box-shadow: 6px 6px 3px #00000015;
}
.headerPortionSingleQR {
  border-bottom: 0px solid #D9D9D9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ellipsisIcon {
  margin-right: 20px;
  margin-top: 4px;
  cursor: pointer;
}
.ellipsisIcon:hover {
  opacity: 0.7;
}
.ellipsisIcon > svg {
  fill: rgb(88, 88, 88);
  width: 13px;
}
.ellipsisIconDefaultClear {
  opacity: 0;
}
.removeSingleQRPopover > p {

  display: flex;
  align-items: center;
  justify-content: center;
}
.removeSingleQRPopover > p {
  font-size: 12px;
  padding: 10px 0;
  width: 135px;
  margin: 2px 0;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-weight: 500;
}
.removeSingleQRPopover > p > span {
  width: 120px;
}
.removeSingleQRPopover > p > svg {
  width: 13px;
  margin-right: 8px;
  margin-left: 12px;
  fill: #787878;
}
.removeSingleQRPopover > p:hover {
  background-color: rgb(232, 232, 232);
}
.headerPortionSingleQR > p {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  padding: 8px 0px 2px 13px;
  display: flex;
  align-items: center;
  color: #000;
}
.headerPortionSingleQR > p > svg {
  padding-right: 8px;
  padding-left: 4px;
  fill: #000;
  width: 15px;
}
/* .headerPortionSingleQR > p > span {
  height: 12px;
  width: 1px;
  margin-right: 10px;
  background-color: #b6b6b6;
} */
.infoPortionSingleQR {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  height: 120px;
}
.infoSectionSingleQR {
  margin-left: 15px;
  height: 90px;
}
.infoSectionSingleQR > p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  height: 15px;
  margin-top: 5px;
}
.infoSectionSingleQR > h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  height: 25px;
  margin-top: 5px;
}
.infoSectionSingleQR > span {
  font-size: 10px;
  font-weight: 500;
  height: 10px;
}
.poplCardLogoSingleQR {
  height: 30px !important;
  margin-bottom: 15px !important;
  display: flex;
  align-items: center;
}
.poplCardLogoSingleQR > span{
  font-size: 14px !important;
  font-weight: 400 !important;
  width: 50px;
  margin-right: 3px;
}
.poplCardLogoSingleQR > img{
  width: 50px;
  margin-right: 3px;
}
.viewsDivSingleQR {
  height: 10px;
  display: flex;
  align-items: center;
}
.viewsDivSingleQR > p {
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #333;
}
.viewsDivSingleQR > p > svg {
  width: 13px;
  margin-right: 6px;
  fill: rgb(132, 132, 132);
}
.btnsDivSingleQR {
  display: flex;
  height: 20px;
  margin-top: 5px;
  width: 135px;
  justify-content: space-between;
  align-items: center;
}
.btnsDivSingleQR > a {
  font-size: 12px;
  font-weight: 600;
  margin-top: 15px;
  text-decoration: none;
  color: #000;

}
.singleQRItemBtn {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
}
.singleQRItemBtn:hover {
  opacity: 0.6;
}
.singleQRItemBtn > svg {
  width: 13px;
  margin-right: 5px;
}
.downloadSingleQRPopover > p {
  font-size: 12px;
  padding: 10px 15px;
  margin: 2px 0;
  transition: 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #333;
  font-weight: 500;
}
.downloadSingleQRPopover > p > svg {
  width: 10px;
  margin-right: 8px;
}
.downloadSingleQRPopover > p:hover {
  background-color: rgb(232, 232, 232);
}
.noSavedQRText > h2{
  margin: 0;
  padding-bottom: 15px;
  font-size: 14px;
  padding-left: 5px;
}
.noSavedQRText > p{
  margin: 0;
  font-size: 12px;
  font-weight: 500;
}
.hamburgerDiv {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 30px;
}
.hamburgerDiv > svg {
  width: 15px;
  cursor: pointer;
}
.collectionsIndex {
  width: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.collectionIndexOption {
  background-color: #F7F7F7;
  width: 140px;
  margin-left: 10px;
  padding: 0 10px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  fill: rgb(0, 0, 0);
  transition: 0.3s;
}
.collectionIndexOption:hover {
  opacity: 0.7;
}
.collectionIndexOption > p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}
.collectionIndexOption > span {
  display: flex;
  align-items: center;
  width: 25px;
}
.collectionIndexOption > span > svg {
  width: 18px;
}
.selectedCollectionIndex {
  padding-right: 20px;
}
.subSectionCollections{
  width: 190px;
  margin-left: 10px;
  padding: 10px 0;
  border-radius: 15px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subSectionCollections > p{
  font-size: 10.5px;
  margin: 0;
  font-weight: 500;
  padding: 5px 13px;
  text-align: center;
  color: #4F4F4F;
}
.nameSectionCollection{
  display: flex;
  align-items: center;
  padding: 7px 0;
  border-radius: 10px;
}
.navImgCollections > img {
  height: 35px;
  border-radius: 100px;
  margin-left: 9px;
  box-shadow: 1px 1px 0px #00000030;
  cursor: pointer;
}
.nameSectionCollection > div {
  margin-left: 10px;
}
.collectionsSettingsSection{
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.dzQuery {
  margin-left: 22px;
  width: 70px;
  margin-bottom: 30px;
}
.dzQuery > p {
  font-size: 11px;
  font-weight: 600;
}
.dzQuery > input {
  width: 100px;
}
.textBtnCollection{
  margin: 8px 0 0 7%;
  font-size: 11px;
  width: 86%;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding: 5px 0;
  color: #212120;
  cursor: pointer;
}
.textBtnCollection:hover {
  opacity: 0.8;
}
.textBtnCollection > svg{
  width: 15px;
  padding-right: 10px;
  margin-left: 15px;
  fill: #828382;
}
.textBtnCollection > a{
  text-decoration: none;
  color: #212120;
}

/* Compare-er */

.comparerCont {
  width: 85%;
  margin-left: 7.5%;
  margin-top: 30px;
  height: auto;
}
.selectedQRSection {
  display: flex;
  margin-left: 5px;
}
.singleSelectedQRWrapper {
  width: 23.5%;
  margin-right: 1.5%;
}
.singleSelectedQR {
  width: 100%;
  height: 115px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
}
.singleSelectedQR:hover {
  box-shadow: 0px 0px 2px 4px #00000008;
}
.singleSelectedQR > div > div > div {
  opacity: 0;
  transition: 0.2s;
}
.singleSelectedQR:hover > div > div > div {
  opacity: 1;
}
.singleSelectedQR > div > svg:hover {
  fill: #636363;
}
.trashCanCancel {
  position: absolute;
  margin-left: 17.5vw;
  margin-top: 5px;
}
.trashCanCancel > svg {
  width: 15px;
  cursor: pointer;
  transition: 0.3s;
  fill: #ffffff00;
}
.innerSelectedCont {
  width: 85%;
  padding: 0 7.5%;
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.textSecSelected {
  margin-left: 14px;
}
.textSecSelected > h1 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  height: 15px;
  padding-bottom: 5px;
}
.textSecSelected > p {
  height: 25px;
  margin: 0;
  padding-bottom: 0px;
  color: rgb(89, 89, 89);
  font-size: 10px;
  width: 11vw;
  overflow-wrap: break-word;
}
.textSecSelected > h6 {
  height: 30px;
  margin: 0;
  padding-top: 5px;
  color: rgb(46, 46, 46);
  font-size: 9px;
  font-weight: 500;
  width: 11vw;
  overflow-wrap: break-word;
}
.borderBottomSelectedQR {
  background-color: #00A4FF;
  box-shadow: 0px 10px 0px 5px #fff;
  width: 100%;
  height: 5px;
  margin-top: 5px;
  border-radius: 15px 15px 0 0;
}
.addSelectedQRBtn {
  width: 18%;
}
.textSecAdd {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 110px;
  border-radius: 15px;
  transition: 0.4s;
  cursor: pointer;
}
.textSecAdd:hover {
  background-color: rgb(248, 248, 248);
}
.textSecAdd > svg {
  width: 18px;
  fill: #000;
}
.textSecAdd > p {
  font-size: 16px;
  margin: 0;
  /* padding-left: 10px; */
  padding-top: 8px;
  color: #000;
  font-weight: 600;
}
.betaBadgeOnAdd {
  width: 40px;
  margin-left: 140px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  opacity: 0.8;
  background-color: rgb(142, 30, 152);
  color: #fff;
  font-size: 10px;
  font-weight: 600;
}
.chooseQRPopover {
  width: 310px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
}
.chooseQRListComparer {
  width: 100%;
  height: auto;
  max-height: 235px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.chooseQRListComparer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chooseQRListComparer {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.singleChooseQRComparerNone {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 13px;
  color: #7d7d7d;
  width: 88%;
  height: 70px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #eeeeee;
}
.singleChooseQRComparer {
  display: flex;
  align-items: center;
  width: 88%;
  border-radius: 10px;
  padding: 8px 5px;
  margin-bottom: 5px;
  transition: 0.2s;
  cursor: pointer;
}
.singleChooseQRComparer:hover {
  background-color: #f3f3f3;
}
.textSecSingleQRComparer {
  margin-left: 10px;
  width: 200px;
}
.textSecSingleQRComparer > h1 {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: #676767;
  padding-bottom: 2px;
}
.textSecSingleQRComparer > h6 {
  margin: 0;
  font-size: 11px;
  color: #A0A0A0;
  font-weight: 500;
  padding-left: 1px;
}
.textSecSingleQRComparer > p {
  margin: 0;
  font-size: 11px;
  color: #A0A0A0;
  font-weight: 400;
  padding-top: 4px;
  padding-left: 1px;
}
.proBadgeOnAdd {
  width: 100%;
  border-radius: 100px;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  opacity: 0.8;
}
.proBadgeOnAdd > svg {
  width: 18px;
  border-radius: 100px;
  margin-right: 15px;
  box-shadow: 0px 0px 10px #00000015;
}
.compareChartSection {
  width: 100%;
  height: 470px;
  background-color: #fff;
  margin-top: 15px;
  border-radius: 0px 20px 20px 20px;
  display: flex;
  justify-content: space-between;
}
.innerLineChart {
  height: 100%;
  padding-left: 20px;
  width: 76%;
}
.h1divCompare {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  padding-left: 20px;
  padding-top: 25px;
  color: #000;
  display: flex;
  align-items: center;
}
.lineCont {
  padding: "0px 60px",
}
#lineChart {
  position: absolute;
  display: flex;
  top: -65px;
  max-width: 100%;
  /* transform: translateX(-50%); */
  /* left: 50%; */
  right: -20px;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  height: 50px;
}

#lineChart::-webkit-scrollbar {
  height: 8px;
  /* высота для горизонтального скролла */
  background-color: #b5b5b5;
  border-radius: 5px;
}

/* ползунок скроллбара */
#lineChart::-webkit-scrollbar-thumb {
  background-color: #545454;
  border-radius: 5px;
}

#lineChart::-webkit-scrollbar-thumb:hover {
  background-color: #1d1d1d;
}
body::-webkit-scrollbar {
  height: 0px;
  width: 0;
  background-color: #ff9ff900;
  border-radius: 5px;
}

/* ползунок скроллбара */
body::-webkit-scrollbar-thumb {
  background-color: #54545400;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #1d1d1d00;
}

.network-container__line {
  margin-top: 90px;
  padding-left: 20px;
  width: 100%;
}

.barChartContainer {
  margin-top: 218px;
  margin-right: 6%;
  /* height: 200px; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/* End Compare-er */

/* End Collections */

.mobileLanding {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.mobileLanding > p {
  width: 200px;
}
/* different loader */
.loaderSmall,
.loaderSmall:before,
.loaderSmall:after {
  background: #bebebe90;
  /* border: 1px solid #000000; */
  -webkit-animation: loadAnimateSingle 1.1s infinite ease-in-out;
  animation: loadAnimateSingle 1.1s infinite ease-in-out;
  width: 2px;
  height: 2px;
  border-radius: 100px;
  opacity: 0.8;
}
.loaderSmall {
  background-color: #4F4BFF80;
  color: #4F4BFF80;
  text-indent: -9999em;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
  /* rotate: 90deg; */
}
.loaderSmall:after {
  background-color: #4BAFFF;
  color: #4BAFFF;
  position: absolute;
  left: -10px;
  content: '';
}
.loaderSmall:before {
  background-color: #3DCFE2;
  color: #3DCFE2;
  position: absolute;
  left: -20px;
  content: '';
}
/* different loader */
.loaderIdle,
.loaderIdle:before,
.loaderIdle:after {
  background: #444;
  /* border: 1px solid #000000; */
  /* -webkit-animation: loadAnimate 1s infinite ease-in-out; */
  /* animation: loadAnimate 1s infinite ease-in-out; */
  width: 5px;
  height: 30px;
  border-radius: 100px;
}
.loaderIdle {
  color: #444;
  text-indent: -9999em;
  margin: 0px auto;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
  /* rotate: 90deg; */
}
.loaderIdle:before,
.loaderIdle:after {
  position: absolute;
  top: -5px;
  content: '';
}
.loaderIdle:before {
  left: -10px;
  /* -webkit-animation-delay: -0.30s;
  animation-delay: -0.30s; */
}
.loaderIdle:after {
  left: 10px;
}
/* different loader */

.loader,
.loader:before,
.loader:after {
  background: #444;
  width: 5px;
  border-radius: 100px;
  -webkit-animation: loadAnimate 1s infinite ease-in-out;
  animation: loadAnimate 1s infinite ease-in-out;
}
.loader {
  color: #4BAFFF;
  transform: translateZ(0);
  animation-delay: -0.15s;
  -webkit-animation-delay: -0.15s;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  /* use for rotate vvv */
  rotate: 0deg;
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
  top: -5px;
}
.loader:before {
  color: #3DCFE2;
  left: -10px;
  -webkit-animation-delay: -0.30s;
  animation-delay: -0.30s;
}
.loader:after {
  color: #4F4BFF;
  left: 10px;
}
/* different loader */

.loaderGrey,
.loaderGrey:before,
.loaderGrey:after {
  background: #aeaeae;
  width: 5px;
  border-radius: 100px;
  -webkit-animation: loadAnimateGrey 2s infinite ease-in-out;
  animation: loadAnimateGrey 2s infinite ease-in-out;
}
.loaderGrey {
  color: #4BAFFF50;
  transform: translateZ(0);
  animation-delay: -0.15s;
  -webkit-animation-delay: -0.15s;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  /* use for rotate vvv */
  rotate: 0deg;
}
.loaderGrey:before,
.loaderGrey:after {
  position: absolute;
  content: "";
  top: 0px;
}
.loaderGrey:before {
  color: #3DCFE250;
  left: -10px;
  -webkit-animation-delay: -0.30s;
  animation-delay: -0.30s;
}
.loaderGrey:after {
  color: #4F4BFF50;
  left: 10px;
}
@-webkit-keyframes loadAnimateSingle {
  0%,
  80%,
  100% {
    box-shadow: 0 0px;
    height: 30px;
  }
  40% {
    box-shadow: 0 -55px;
    height: 15px;
  }
}
@keyframes loadAnimateSingle {
  0%,
  80%,
  100% {
    box-shadow: 0 0px;
    height: 10px;
  }
  40% {
    box-shadow: 0 -10px;
    height: 4px;
  }
}
@-webkit-keyframes loadAnimate {
  0%,
  80%,
  100% {
    box-shadow: 0 0px;
    height: 30px;
  }
  40% {
    box-shadow: 0 -55px;
    height: 15px;
  }
}
@keyframes loadAnimate {
  0%,
  80%,
  100% {
    box-shadow: 0 0px;
    height: 10px;
  }
  40% {
    box-shadow: 0 -55px;
    height: 15px;
  }
}
@-webkit-keyframes loadAnimateGrey {
  0%,
  80%,
  100% {
    box-shadow: 0 0px;
    height: 10px;
  }
  40% {
    box-shadow: 0 -5px;
    height: 15px;
  }
}
@keyframes loadAnimateGrey {
  0%,
  80%,
  100% {
    box-shadow: 0 0px;
    height: 10px;
  }
  40% {
    box-shadow: 0 -5px;
    height: 15px;
  }
}



/* Start Media Queries */

/* @media screen and (min-height: 850px) {
  .generatorContainer {
    height: 700px;
  }
} */
@media screen and (max-width: 900px) {
  .displayNoneMobile {
    display: none !important;
  }
  .displayOnMobile {
    display: block;
  }
  /* Auth */

  .mobileCont {
    position: absolute;
    top: 0;
    left: 0;
  }

.authNav {
  display: flex;
  top: 0;
  width: 80%;
  height: 100%;
  background-color: #b70d0d00;
  justify-content: space-between;
  z-index: 99;
  transition: all 0.4s ease;
  margin-left: 10%;
  margin-top: 10px;
}
.leftNavAuth {
  width: 20%;
  margin-left: 4%;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}
.leftNavAuth > img {
  width: auto;
  height: 50px;
}
.leftNavAuth > h1 {
  font-size: 20px;
  color: #333;
  margin: 0;
  padding-top: 2px;
  padding-left: 15px;
  font-weight: 700;
}
.leftNavAuth > div {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-left: 8px;
}
.leftNavAuth > div > p {
  font-size: 12px;
  color: #000;
  font-weight: 400;
}
.leftNavAuth > div > img {
  font-size: 5px;
  margin-left: 4px;
  width: 40px;
  height: auto;
}
.popupContainerAuth{
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signInText {
  font-weight: 500;
}
.createAccountPopup {
  width: 350px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 60px;
  margin-top: 40px;
  box-shadow: 4px 4px 3px #00000015;
}
.logoSectionCreatePopup {
  width: 350px;
  height: 90px;
}
.topBlackBarCreate {
  background-color: #000;
  height: 40px;
  width: 350px;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topBlackBarCreate > img {
  width: 50px;
  padding-top: 40px;
}
.bottomBlackBarCreate {
  background-color: #000000;
  height: 50px;
  width: 350px;
  border-radius: 0 0 100% 100%;
}
.inputsSectionCreatePopup {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  width: 300px;
  margin-left: 25px;
}
.inputsSectionCreatePopup > h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 25px;
}
.fancyOr {
  color: #c5c5c5;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.fancyOr > div {
  height: 1px;
  width: 110px;
  background-color: #c5c5c5;
  margin: 0 5px;
}
.fancyOrSmall {
  color: #c5c5c5;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.fancyOrSmall > div {
  height: 1px;
  width: 110px;
  background-color: #c5c5c5;
  margin: 0 5px;
}
.forgotPassP {
  font-size: 13px;
  color: #A8A8A8;
  margin: 0px 0 25px 0;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.forgotPassP > a {
  text-decoration: none;
  cursor: pointer;
  color: #A8A8A8;
}
.alreadyHaveAccP {
  font-size: 13px;
  color: #A8A8A8;
  margin: 30px 0 25px 0;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.alreadyHaveAccP > span {
  color: #5092f6;
  cursor: pointer;
  margin-left: 3px;
  display: flex;
  align-items: center;
}
.alreadyHaveAccP > span > svg {
  fill: #000;
  width: 9px;
  margin-right: 10px;
}

/* End Auth */

/* Landing Page */

/* Navbar */
.navButton {
  font-weight: 700;
  text-decoration: none;
  color: rgb(0, 0, 0);
  display: inline-block;
  font-size: 0.8vw;
  z-index: 100;
}
#navButton2 {
  font-weight: 500;
  color: #c2c2c2;
  z-index: 100;
}
.navButton:after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  background: #7d12bfaa;
  transition: 300ms;
}

.navButton:hover:after {
  width: 100%;
}
.navBarHomeContainer {
  width: 100vw;
  height: 70px;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 100;
}
.navBarHome {
  width: 100%;
  height: 70px;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid #dcdcdc;
}
.navBarHomeMobile {
  display: none;
}
.navContent {
  display: flex;
  top: 0;
  width: 90%;
  height: 100%;
  background-color: #b70d0d00;
  justify-content: space-between;
  z-index: 99;
  transition: all 0.4s ease;
  margin-left: 5%;
}
.leftNav {
  width: 20%;
  margin-left: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
}
.leftNav > img {
  width: auto;
  height: 37px;
}
.leftNav > h1 {
  font-size: 16px;
  color: #333;
  margin: 0;
  padding-top: 2px;
  padding-left: 10px;
  font-weight: 700;
}
.leftNav > h1 > span {
  color: #2b2b2b;
  font-size: 13px;
  font-weight: 600;
}
.leftNav > p {
  font-size: 0.7vw;
  color: #333;
  margin: 0;
  padding-top: 0.6vw;
  padding-left: 0.5vw;
  font-weight: 600;
}
.leftNav > div {
  display: none;
  align-items: center;
  margin-top: 30px;
  margin-left: 8px;
}
.leftNav > div > p {
  font-size: 12px;
  color: #000;
  font-weight: 400;
}
.leftNav > div > img {
  font-size: 5px;
  margin-left: 4px;
  width: 40px;
  height: auto;
}
.rightNavHome {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navButtonsFlex{
  display: flex;
}
.borderBottomGradientHome {
  width: 100%;
  height: 7px;
  background-image: linear-gradient(
    90deg,
    #0055ff 1.54%,
    #7896ff 17.83%,
    #4000f0 35.64%,
    #ff00aa 56.51%,
    #f9f900 83.48%,
    #0055ff 99.26%
  );
  z-index: 200;
  animation: lightBar 5s linear infinite;
  border-radius: 100%;
}

/* End Navbar */

/* Login/Auth */

.loginPopUp{
  width: 300px;
  height: auto;
  padding: 10px 10px 30px 10px;
  border-radius: 150px;
}
.loginFormInputs{
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginFormInputs > img {
  height: 28px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.seperatorDivLogin{
  width: 90%;
  height: 1px;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #dfdfdf;
}

/* End Login/Auth */

/* Account Popup */
.MuiPopover-paper {
  border-radius: 10px !important;
}
.accountPopUp{
  width: 260px;
  height: auto;
  padding: 10px 0;
}
.nameSectionAccount{
  width: 86%;
  margin-left: 7%;
  display: flex;
  align-items: center;
  border: 1.5px solid #EAEAEA;
  padding: 7px 0;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 8px;
}
.nameSectionAccount > img {
  height: 42px;
  border-radius: 100px;
  margin-left: 9px;
  box-shadow: 2px 2px 2px #00000015;
}
.nameSectionAccount > div {
  margin-left: 15px;
}
.nameSectionAccount > div > p {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}
.nameSectionAccount > div > span {
  font-weight: 500;
  font-size: 12px;
}
.otherSectionAccount{
  width: 86%;
  margin-left: 7%;
  display: flex;
  align-items: center;
  border: 1.5px solid #eaeaea;
  padding: 7px 0;
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.2s;
  text-decoration: none !important;
  color: #000 !important;
}
.otherSectionAccount:hover{
  border: 1.5px solid #b9b9b9;
}
.otherSectionAccount > img {
  height: 42px;
  border-radius: 100px;
  margin-left: 9px;
  box-shadow: 2px 2px 2px #00000015;
}
.otherSectionAccount > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 25px;
}
.otherSectionAccount > div > svg {
  width: 20px;
}
.otherSectionAccount > div > img {
  width: 35px;
}
.otherSectionAccount > p {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
}
.poplTeamsBtnAccount {
  flex-direction: column;
}
.poplTeamsBtnAccount > img {
  width: 30px !important;
}
.poplTeamsBtnAccount > span {
  font-size: 7px;
}
.logoutBtnAccount {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  margin-left: 10%;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #757575;
}
.logoutBtnAccount:hover {
  color: #333;
}

/* End Account Popup */

.mobileSelectType {
  margin-top: 100px;
}

}
